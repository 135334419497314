import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ImageGallery from "react-image-gallery"

// Components
import Header from "../components/navigation/header"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Layout from "../components/layout"

// Styles
import styles from "../styles/templates/product.module.scss"
import { FaEnvelope, FaPhone } from "react-icons/fa"
import { Button, Col, Container, Row } from "react-bootstrap"

export const query = graphql`
  query($slug: String!) {
    contentfulProductNew(slug: { eq: $slug }) {
      name
      slug
      images {
        fluid(maxWidth: 500, quality: 100) {
          src
        }
      }
      description {
        json
      }
      price
    }
  }
`
const Product = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        if (!node.data.target.fields) {
          return
        }
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  const images = props.data.contentfulProductNew.images.map(img => {
    return { original: img.fluid.src, thumbnail: img.fluid.src }
  })

  return (
    <>
      <Header />
      <SEO
        title={props.data.contentfulProductNew.name}
        pathname={`/products/${props.data.contentfulProductNew.slug}`}
        templateImage={props.data.contentfulProductNew.images[0].fluid.src}
      />
      <Layout>
        <Container>
          <Row>
            <Col md={6} className="px-3 mb-5 d-flex justify-content-center">
              <ImageGallery
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
              />
            </Col>
            <Col md={6}>
              <h2 style={{ fontWeight: 600 }}>
                {props.data.contentfulProductNew.name}
              </h2>
              <div className={`mt-5 ${styles.description}`}>
                {documentToReactComponents(
                  props.data.contentfulProductNew.description.json,
                  options
                )}
              </div>
              {props.data.contentfulProductNew.price ? (
                <h3 className="mt-5" style={{ fontWeight: 600 }}>
                  LKR{" "}
                  {props.data.contentfulProductNew.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h3>
              ) : (
                ""
              )}
              <a href="tel:+94775008888">
                <Button className="mt-4">Call Now</Button>
              </a>
              <div>
                <ul className={`mt-5 ${styles.list}`}>
                  <li>
                    <a href="tel:+94775008888" target="_top">
                      <span className={styles.icon}>
                        <FaPhone />
                      </span>
                      +94 77 500 8888
                    </a>
                  </li>
                  <li>
                    <a href="tel:+94705008888" target="_top">
                      <span className={styles.icon}>
                        <FaPhone />
                      </span>
                      +94 70 500 8888
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@sunterra.lk" target="_top">
                      <span className={styles.icon}>
                        <FaEnvelope />
                      </span>
                      info@sunterra.lk
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
      <Footer />
    </>
  )
}

export default Product
